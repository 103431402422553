var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list",on:{"click":function($event){return _vm.selectCard()}}},[_c('div',{staticClass:"informacao"},[_c('div',{staticClass:"nome"},[(_vm.modelo.Nome != undefined)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(_vm.modelo.Nome))}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(_vm.modelo.Valor))}})]),_c('div',{staticClass:"linha"}),_c('div',{staticClass:"descricao--shrink"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(_vm.modelo.Descricao))}})])]),_c('span',{staticClass:"fav-box"},[_c('div',{staticClass:"checkbox"},[(_vm.modelo)?_c('div',[_c('input',{staticClass:"input",class:{
            disable: _vm.desabilitaAlerta && _vm.modelo.Valor == 'BOXSUPPJ009',
          },attrs:{"type":"checkbox","disabled":_vm.desabilitaAlerta && _vm.modelo.Valor == 'BOXSUPPJ009'},domProps:{"checked":_vm.verificaSelecao()},on:{"change":_vm.selectCard,"click":_vm.selectCard}})]):_vm._e()]),(
        !_vm.modelosPreferidos.includes(_vm.modelo.Valor) &&
        !_vm.modelo.Valor.startsWith('BOXSUPP') &&
        !_vm.modelo.Valor.includes('KYC')
      )?_c('span',{staticClass:"fav -not",on:{"click":function($event){_vm.trocarFavorito(_vm.modelo);
        $event.stopPropagation();}}},[_c('img',{staticClass:"fav-img",attrs:{"src":"/img/selecao-produtos/estrela-n.png","alt":""}})]):(
        _vm.modelosPreferidos.includes(_vm.modelo.Valor) &&
        !_vm.modelo.Valor.startsWith('BOXSUPP') &&
        !_vm.modelo.Valor.includes('KYC')
      )?_c('span',{staticClass:"fav",on:{"click":function($event){_vm.trocarFavorito(_vm.modelo);
        $event.stopPropagation();}}},[_c('img',{staticClass:"fav-img",attrs:{"src":"/img/selecao-produtos/estrela-preenchida.png","alt":""}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }