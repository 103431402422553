var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-map"},[_c('div',{staticClass:"row-map"},[_c('div',{staticClass:"options-box sb-2 sb-right"},[_c('span',{staticClass:"titleBlue",class:{
          'disabled-title': _vm.mapaEmpresas.length === 0,
        }},[_vm._v(" Pontos de Visualização "),_c('br'),_vm._v(" (Brasil) ")]),_c('span',{staticClass:"item",class:{
          'disabled-border': _vm.mapaEmpresas.length === 0,
        }},[_c('span',{staticClass:"texto",attrs:{"id":'MapaEmpresa-ativas'}},[_vm._v(" Ativas ")]),_c('span',{staticClass:"check-box",attrs:{"id":'MapaEmpresa-check-ativas'}},[_c('md-switch',{staticClass:"sw",attrs:{"disabled":_vm.mapaEmpresas.length === 0 || _vm.empresasAtivas.length == 0},on:{"change":function($event){return _vm.atualizaMapa()}},model:{value:(_vm.checkAtivas),callback:function ($$v) {_vm.checkAtivas=$$v},expression:"checkAtivas"}})],1)]),_c('span',{staticClass:"item",class:{
          'disabled-border': _vm.mapaEmpresas.length === 0,
        }},[_c('span',{staticClass:"texto",attrs:{"id":'MapaEmpresa-encerradas'}},[_vm._v(" Encerradas ")]),_c('span',{staticClass:"check-box",attrs:{"id":'MapaEmpresa-check-encerradas'}},[_c('md-switch',{staticClass:"sw",attrs:{"disabled":_vm.mapaEmpresas.length === 0 || _vm.empresasEncerradas.length == 0},on:{"change":function($event){return _vm.atualizaMapa()}},model:{value:(_vm.checkEncerradas),callback:function ($$v) {_vm.checkEncerradas=$$v},expression:"checkEncerradas"}})],1)]),_c('span',{staticClass:"item",class:{
          'disabled-border': _vm.mapaEmpresas.length === 0,
        }},[_c('span',{staticClass:"texto",attrs:{"id":'MapaEmpresa-esconder'}},[_vm._v(" Esconder Filiais ")]),_c('span',{staticClass:"check-box",attrs:{"id":'MapaEmpresa-check-esconder'}},[_c('md-switch',{staticClass:"sw",attrs:{"disabled":_vm.mapaEmpresas.length === 0 || _vm.empresasFiliais.length == 0},on:{"change":function($event){return _vm.atualizaMapa()}},model:{value:(_vm.checkEscondeFiliais),callback:function ($$v) {_vm.checkEscondeFiliais=$$v},expression:"checkEscondeFiliais"}})],1)]),_c('div',{staticClass:"pin-box"},[_c('span',{staticClass:"pin"},[_c('img',{attrs:{"src":_vm.blue}}),_vm._v(" Matrizes ")]),_c('span',{staticClass:"pin"},[_c('img',{attrs:{"src":_vm.purple}}),_vm._v(" Filiais ")]),_c('span',{staticClass:"pin"},[_c('img',{attrs:{"src":_vm.red}}),_vm._v(" Encerradas ")])])]),(this.centro.coordenada != undefined && _vm.mapaEmpresas.length != 0)?_c('div',{staticClass:"map-box"},[_c('Maps',{ref:"mapComponent",attrs:{"center":_vm.centro,"marcas":_vm.marcas,"poligonos":_vm.poligonos,"dimensoes":{ width: '785px', height: '430px' },"zoom":_vm.zoomMapa,"gmapCircle":false}})],1):_vm._e(),(_vm.mapaEmpresas.length == 0)?_c('div',{staticClass:"map-img-box",staticStyle:{"width":"775px","height":"430px"}},[_c('img',{staticClass:"map-img",staticStyle:{"width":"775px","height":"430px"},attrs:{"src":"/img/superbox/mapa-brasil.png"}})]):_vm._e()]),(_vm.abreBloco)?_c('div',_vm._l((_vm.tooltipsMapaEmpresa),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window","customClass":"tooltip-custom"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }