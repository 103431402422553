<template>
  <div class="container-map">
    <div class="row-map">
      <div class="options-box sb-2 sb-right">
        <span
          class="titleBlue"
          :class="{
            'disabled-title': mapaEmpresas.length === 0,
          }"
        >
          Pontos de Visualização <br />
          (Brasil)
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto" :id="'MapaEmpresa-ativas'"> Ativas </span>
          <span class="check-box" :id="'MapaEmpresa-check-ativas'">
            <md-switch
              v-model="checkAtivas"
              @change="atualizaMapa()"
              class="sw"
              :disabled="
                mapaEmpresas.length === 0 || empresasAtivas.length == 0
              "
            >
            </md-switch>
          </span>
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto" :id="'MapaEmpresa-encerradas'"> Encerradas </span>
          <span class="check-box" :id="'MapaEmpresa-check-encerradas'">
            <md-switch
              v-model="checkEncerradas"
              @change="atualizaMapa()"
              class="sw"
              :disabled="
                mapaEmpresas.length === 0 || empresasEncerradas.length == 0
              "
            >
            </md-switch>
          </span>
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto" :id="'MapaEmpresa-esconder'">
            Esconder Filiais
          </span>
          <span class="check-box" :id="'MapaEmpresa-check-esconder'">
            <md-switch
              v-model="checkEscondeFiliais"
              @change="atualizaMapa()"
              class="sw"
              :disabled="
                mapaEmpresas.length === 0 || empresasFiliais.length == 0
              "
            >
            </md-switch>
          </span>
        </span>

        <div class="pin-box">
          <span class="pin">
            <img :src="blue" />
            Matrizes
          </span>
          <span class="pin">
            <img :src="purple" />
            Filiais
          </span>
          <span class="pin">
            <img :src="red" />
            Encerradas
          </span>
        </div>
      </div>
      <div
        class="map-box"
        v-if="this.centro.coordenada != undefined && mapaEmpresas.length != 0"
      >
        <Maps
          :center="centro"
          :marcas="marcas"
          :poligonos="poligonos"
          :dimensoes="{ width: '785px', height: '430px' }"
          :zoom="zoomMapa"
          :gmapCircle="false"
          ref="mapComponent"
        />
      </div>
      <div
        class="map-img-box"
        v-if="mapaEmpresas.length == 0"
        style="width: 775px; height: 430px"
      >
        <img
          src="/img/superbox/mapa-brasil.png"
          class="map-img"
          style="width: 775px; height: 430px"
        />
      </div>
    </div>
    <div v-if="abreBloco">
      <div v-for="(tt, i) in tooltipsMapaEmpresa" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapasService } from "@/services";

import Maps from "@/components/Maps.vue";
import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";

export default {
  name: "EmpresaMapa",
  components: {
    Maps,
  },

  props: {
    enderecos: Array,
    index: Number,
    abreBloco: Boolean,
    codigo_agrupador: String,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      checkAtivas: true,
      checkEncerradas: false,
      checkEscondeFiliais: false,

      red: "/img/superbox/pin-vermelho.png",
      blue: "/img/superbox/pin-azul.png",
      green: "/img/superbox/pin-verde.png",
      yellow: "/img/superbox/pin-laranja.png",
      purple: "/img/superbox/pin-roxo.png",

      centro: {},
      marcas: [],
      poligonos: [],

      mapaEmpresas: [],
      zoomMapa: 4,
    };
  },

  watch: {},

  computed: {
    portesDisponiveis() {
      const portes = new Set();
      this.mapaEmpresas.forEach((empresa) => {
        if (empresa.porte_empresa && !portes.has(empresa.porte_empresa)) {
          portes.add(empresa.porte_empresa);
        }
      });
      return portes;
    },

    tiposDisponiveis() {
      const tipos = new Set();
      this.mapaEmpresas.forEach((empresa) => {
        if (empresa.tipo_empresa && !tipos.has(empresa.tipo_empresa)) {
          tipos.add(empresa.tipo_empresa);
        }
      });
      return tipos;
    },

    empresasAtivas() {
      return this.mapaEmpresas.filter((empresa) => empresa.flag_empresa_ativa);
    },

    empresasEncerradas() {
      return this.mapaEmpresas.filter(
        (empresa) => empresa.flag_empresa_encerrada
      );
    },

    empresasFiliais() {
      return this.mapaEmpresas.filter(
        (empresa) => !empresa.flag_empresa_matriz
      );
    },

    tooltipsMapaEmpresa() {
      return listaTooltipsModelos.bloco_info_mapa;
    },
  },

  methods: {
    async configuraMapa() {
      // await this.adicionaMarcaCentro();

      this.mapaEmpresas = await mapasService.recuperaCoordenadasEmpresa(
        this.codigo_agrupador
      );

      this.mapaEmpresas.forEach((empresa) => {
        if (empresa.flag_empresa_encerrada && !empresa.flag_empresa_ativa) {
          empresa.iconColor = this.red;
          let texto = `<b>Encerrada</b> <br/> `;
          empresa.descricao = texto;
        } else if (
          !empresa.flag_empresa_matriz &&
          empresa.flag_empresa_ativa &&
          !empresa.flag_empresa_encerrada
        ) {
          empresa.iconColor = this.purple;
          let texto = `<b>Filial</b> <br/> `;
          empresa.descricao = texto;
        } else if (
          empresa.flag_empresa_matriz &&
          !empresa.flag_empresa_encerrada &&
          empresa.flag_empresa_ativa
        ) {
          empresa.iconColor = this.blue;
          let texto = `<b>Matriz</b> `;
          empresa.descricao = texto;
        } else {
          empresa.iconColor = this.blue;
        }
      });

      this.atualizaMapa();
    },

    atualizaMapa() {
      let tempAtivas = [];
      let tempEncerradas = [];
      this.mapaEmpresas = this.organizaListaPorMatriz(this.mapaEmpresas);

      if (this.checkAtivas) {
        tempAtivas = this.mapaEmpresas.filter(
          (empresa) => empresa.flag_empresa_ativa
        );
      }
      if (this.checkEncerradas) {
        tempEncerradas = this.mapaEmpresas.filter(
          (empresa) => empresa.flag_empresa_encerrada
        );
      }

      let combinado = [...tempAtivas, ...tempEncerradas];
      let unicos = [];

      combinado.forEach((item) => {
        if (!unicos.some((unico) => unico === item)) {
          unicos.push(item);
        }
      });

      if (this.checkEscondeFiliais) {
        this.marcas = unicos.filter((empresa) => empresa.flag_empresa_matriz);
      } else {
        this.marcas = unicos;
      }
    },

    organizaListaPorMatriz(lista) {
      return lista.sort((a, b) => {
        return a.flag_empresa_matriz - b.flag_empresa_matriz;
      });
    },

    async adicionaMarcaCentro() {
      const centroIndex = this.marcas.findIndex(
        (marca) => marca.descricao === "Centro"
      );

      if (centroIndex === -1) {
        this.marcas.push({
          coordenada: this.centro.coordenada,
          iconColor: this.red,
          descricao: "Centro",
        });
      }
    },

    async buscarCentro() {
      let tentativas = 0;
      const maximoTentativas = 10;
      const esperar = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      while (
        this.centro.coordenada === undefined &&
        tentativas < maximoTentativas
      ) {
        try {
          this.centro = await mapasService.recuperaCoordenadasPrincipal(
            this.codigo_agrupador
          );
          if (this.centro.coordenada !== undefined) {
            break;
          }
        } catch (erro) {
        }

        await esperar(1500);

        tentativas++;
      }
    },
  },

  async mounted() {
    if (this.codigo_agrupador != "") {
      await this.buscarCentro();
    }

    await this.configuraMapa();

    if (
      this.empresasAtivas.length == 0 &&
      this.empresasEncerradas.length != 0
    ) {
      this.checkEncerradas = true;
    }

    if (this.mapaEmpresas.length === 0 || this.empresasAtivas.length == 0) {
      this.checkAtivas = false;
    }

    this.atualizaMapa();
  },
};
</script>

<style lang="scss" scoped>
.container-map {
  display: flex;
  flex-direction: column;
  margin: 0 5px 10px 5px;
}
.row-map {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.map-box {
  display: flex;
}

.titleBlue {
  background-color: #0070c0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  height: 80px;
  cursor: default;
  line-height: 14px;
}

.item {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  border: solid 1px #0070c0;
  color: #0070c0;
  cursor: default;
}

.texto {
  width: 50%;
  line-height: 14px;

  &.icon-box {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    align-items: center;
    width: 93px;
  }

  .icon {
    fill: #0070c0;
  }
}

.select-box {
  width: 40px;
  display: flex;
  justify-content: flex-start;
}

.selectFav {
  width: 20px;
}

.sw {
  width: 50%;
  text-align: right;
  margin: 0 0 0 10px;
}

.pin-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

.pin {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.disabled {
  background-color: #ebebeb !important;
  cursor: not-allowed;
}

.disabled-title {
  background-color: #a7a7a7;
  color: white;
}

.disabled-border {
  color: #a7a7a7;
  border: solid 1px #a7a7a7;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
